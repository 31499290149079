import FreshdeskLogo from '../images/freshdesk-logo.svg';
import FreshserviceLogo from '../images/freshservice-logo.svg';
import FreshsuccessLogo from '../images/freshsuccess-logo.svg';
import FreshteamLogo from '../images/freshteam-logo.svg';
import FreshworksCRMLogo from '../images/freshworks-crm_logo.svg';
import FreshchatLogo from '../images/freshchat-logo.svg';
import FreshcallerLogo from '../images/freshcaller-logo.svg';

export const FP_USER_LOCALSTATE = 'fp-u-state'; // 'first' | 'visited'

export const FILTER_TABS = [
  {
    id: 'all',
    label: 'All Checks',
    plan: 'ALL'
  },
  {
    id: 'up',
    label: 'Up',
    plan: 'ALL'
  },
  {
    id: 'down',
    label: 'Down',
    plan: 'ALL'
  },
  {
    id: 'pause',
    label: 'Paused',
    plan: 'ALL'
  }
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const DATE_FILTERS = [
  {
    value: 4464,
    label: '6 mo'
  },
  {
    value: 2232,
    label: '3 mo'
  },
  {
    value: 720,
    label: '1 mo'
  },
  {
    value: 168,
    label: '1 w'
  },
  {
    value: 24,
    label: '24 hrs'
  },
  {
    value: 1,
    label: '1 hr'
  }
];

export const PLAN_META = {
  recommended: 'BLOSSOM',
  addons: {
    ten_check_monthly: 10,
    twentyfive_check_monthly: 25,
    fifty_check_monthly: 50,
    badge_monthly: 0,
    ten_check_yearly: 10,
    twentyfive_check_yearly: 25,
    fifty_check_yearly: 50,
    badge_yearly: 0
  }
};

export const DEFAULT_RESPONESE_TIME = {
  report_start: '2019-05-15T04:49:32.496Z',
  response_times: [
    {
      duration_seconds_not_responding: null,
      end: '2019-05-15T05:25:20.567Z',
      duration_seconds_reporting_error: null,
      average_response_time_milliseconds_available: 842,
      start: '2019-05-15T05:25:20.567Z',
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 0
    },
    {
      duration_seconds_not_responding: null,
      end: '2019-05-15T05:45:20.472Z',
      duration_seconds_reporting_error: null,
      average_response_time_milliseconds_available: 479,
      start: '2019-05-15T05:45:20.472Z',
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 0
    },
    {
      duration_seconds_not_responding: null,
      end: '2019-05-15T05:25:20.567Z',
      duration_seconds_reporting_error: null,
      average_response_time_milliseconds_available: 842,
      start: '2019-05-15T05:25:20.567Z',
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 0
    },
    {
      duration_seconds_not_responding: null,
      end: '2019-05-15T05:45:20.472Z',
      duration_seconds_reporting_error: null,
      average_response_time_milliseconds_available: 479,
      start: '2019-05-15T05:45:20.472Z',
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 0
    },
    {
      duration_seconds_not_responding: null,
      end: '2019-05-15T05:25:20.567Z',
      duration_seconds_reporting_error: null,
      average_response_time_milliseconds_available: 842,
      start: '2019-05-15T05:25:20.567Z',
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 0
    },
    {
      duration_seconds_not_responding: null,
      end: '2019-05-15T05:45:20.472Z',
      duration_seconds_reporting_error: null,
      average_response_time_milliseconds_available: 479,
      start: '2019-05-15T05:45:20.472Z',
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 0
    }
  ],
  report_end: '2019-05-15T05:49:32.496Z',
  aggregate_by: 'none'
};

export const GRAPH_RESULTS = {
  report_start: '2019-05-04T07:13:28.631Z',
  response_times: [
    {
      duration_seconds_not_responding: null,
      end: '2019-05-08T11:13:28.631Z',
      duration_seconds_reporting_error: null,
      start: '2019-05-07T00:00:00Z',
      average_response_time_milliseconds_available: 250,
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 126808
    },
    {
      duration_seconds_not_responding: null,
      end: '2019-05-06T23:59:59.999Z',
      duration_seconds_reporting_error: null,
      average_response_time_milliseconds_available: 234,
      start: '2019-05-06T00:00:00Z',
      average_response_time_milliseconds_reporting_error: null,
      duration_seconds_available: 86399
    }
  ],
  report_end: '2019-05-08T11:13:28.631Z',
  aggregate_by: 'days'
};

export const INTEREST_LOG = {
  basicauth: {
    msg: 'Basic Authentication and Custom Headers is availabe in',
    planname: 'Blossom plan'
  },
  advancedsettings: {
    msg: 'String check, Status check and Request timeout are available in',
    planname: 'Blossom plan'
  },
  sslexpiryalert: {
    msg:
      'Performance Alerting, SSL Monitoring and SSL expiry alert are availabe in',
    planname: 'Garden plan'
  },
  responsegraph: {
    msg:
      'Dashboard Apdex, Avg. response time and Response time graph are available in',
    planname: 'Garden plan'
  },
  downloadincidentlog: {
    msg: 'Downloading incident and downtime logs is available in',
    planname: 'Garden plan'
  }
};

export const INTEREST_CONFIG = {
  noaccess: {
    heading: 'Contact us'
  },
  access: {
    msg:
      'Your request is submitted successfully. We will reach you out in 3 business days.',
    heading: 'Account suspension - Contact us'
  },
  downgrade: {
    msg:
      "Are you sure? You will loose all features in your current plan. Your downgrade request will be processed in 3 working days. We'll reach out to you for more details.",
    heading: 'Request for Downgrade',
    submit: 'Submit downgrade request',
    cancel: 'Go back'
  },
  freebadgeaccess: {
    msg:
      'Badges are part of Estate plan. You can get it for FREE by reviewing us. You will be redirected to free badge request survey now. Request might take few days to get processed.',
    heading: 'Free Badge request',
    submit: 'Request for FREE Badge',
    cancel: 'Cancel'
  },
  getssl: {
    msg:
      'We can get SSL certificate for your custom domain status page in 15 days. Please submit a request now to confirm. ',
    heading: 'Request for Custom domain SSL',
    submit: 'Submit request',
    cancel: 'Go back'
  }
};

export const PLANS_FEATURES = {
  SPROUT: {
    default: [
      '50 Checks 1 min interval',
      '10 Global locations',
      '5 Public status pages',
      '10 Users',
      '6 Months data retention',
      '5 Integrations'
    ],
    additional: {
      advanced_monitoring: {
        title: 'Advanced monitoring',
        features: [
          'HTTP monitoring',
          'ICMP ping monitoring',
          'TCP/UDP monitoring',
          'DNS monitoring',
          'Pause monitoring'
        ]
      },
      advanced_reporting: {
        title: 'Advanced reporting',
        features: [
          'Response time graphs',
          'Avg response time metrics',
          'Apdex metrics'
        ]
      },
      integrations: {
        title: 'Integrations',
        features: [
          'Zapier',
          'Slack, Twilio, Webhook',
          'Freshdesk, Freshservice'
        ]
      }
    }
  },
  BLOSSOM: {
    default: ['12 Users', '10 Integrations', '12 Months data, Reports & logs'],
    additional: {
      advanced_monitoring: {
        title: 'Advanced monitoring',
        features: [
          'Check response string every min',
          'Check status code every min',
          'SSL monitoring',
          'SSL expiration warnings'
        ]
      },
      advanced_settings: {
        title: 'Advanced settings',
        features: [
          'Set Custom HTTP headers',
          'Set Request timeout',
          'Set Basic authentication'
        ]
      },
      advanced_alerting: {
        title: 'Advanced alerting',
        features: ['Alert only when down for “X” mins']
      }
    }
  },
  GARDEN: {
    default: ['50 Users', '15 Integrations', '24 Months data, Reports & logs'],
    additional: {
      advanced_alerting: {
        title: 'Advanced alerting',
        features: ['Performance alerting']
      },
      advanced_reporting: {
        title: 'Advanced Reporting',
        features: ['Downloadable incident logs']
      },
      public_status_badge: {
        title: 'Public status page',
        features: ['SSL for custom domains']
      }
    }
  }
};

export const FRESHWORKS_PRODUCTS = [
  {
    name: 'Freshdesk',
    category: 'Customer Experience',
    isFeatured: true,
    description: 'A comprehensive helpdesk for exceptional customer service.',
    features: [
      'Ticketing',
      'Collaboration',
      'Automations',
      'Help Widget',
      'Self-service',
      'Field service Mgmt',
      'Team Inbox',
      'Agent Collision Detection',
      'Ticket Customization'
    ],
    colorCode: '#00b647',
    url: 'https://freshworks.com/freshdesk/signup?tactic_id=3631362',
    logo: FreshdeskLogo
  },
  {
    name: 'Freshservice',
    category: 'IT Service Management',
    isFeatured: true,
    description: 'Right-size your IT service management.',
    url: 'https://freshworks.com/freshservice/signup?tactic_id=3631362',
    features: [
      'IT Service Desk',
      'Incident Management',
      'Service Catalog',
      'Problem management',
      'Change management',
      'Release management',
      'IT Project Management',
      'IT Asset Management',
      'CMBD'
    ],
    colorCode: '#009ed7',
    logo: FreshserviceLogo
  },
  {
    name: 'Freshworks CRM',
    category: 'Sales and Marketing',
    isFeatured: true,
    description:
      'Unite sales and marketing around the customer with an all-in-one CRM.',
    url: 'https://www.freshworks.com/crm/signup/?tactic_id=3631362',
    features: [
      'Contact Management',
      'Conversion Rate Optimization',
      'Chat campaigns',
      'Customer Segmentation',
      'Deal Management',
      'Email Campaigns',
      'Sales Analtyics',
      'Sales Sequences',
      'Web forms',
      'Workflow automation'
    ],
    colorCode: '#fa7400',
    logo: FreshworksCRMLogo
  },
  {
    name: 'Freshdesk Messaging',
    category: 'Customer Experience',
    description:
      'Messaging and chatbot-first support for today’s digital customer.',
    url:
      'https://www.freshworks.com/live-chat-software/signup?tactic_id=3631362',
    features: [
      'Messaging Channels',
      'Chatbots',
      'Campaigns',
      'Triggered Messages',
      'User Segmentation',
      'Campaign Insights',
      'User Intel',
      'CoBrowsing',
      'Priority Inbox'
    ],
    colorCode: '#00b647',
    logo: FreshchatLogo
  },
  {
    name: 'Freshdesk Contact Center',
    category: 'Customer Experience',
    description:
      'A reliable and smart call center solution for effortless customer experience.',
    url:
      'https://www.freshworks.com/freshcaller-cloud-pbx/signup?tactic_id=3631362',
    colorCode: '#00b647',
    features: [
      'Global Phone Numbers',
      'Port-in Numbers',
      'Multi-Level IVR',
      'Custom Greetings',
      'Shared Lines',
      'Wait Queues',
      'SIP connections',
      'Call Barging',
      'Call Masking'
    ],
    logo: FreshcallerLogo
  },
  {
    name: 'Freshteam',
    category: 'HR Management',
    description: 'Modernize HR with a unified digital experience.',
    url: 'https://www.freshworks.com/hrms/signup?tactic_id=3631362',
    colorCode: '#825ad8',
    features: [
      'Applicant Tracking',
      'Hiring workflows',
      'Smart Automations',
      'Onboarding',
      'Rich Employee profiles',
      'Employee Directory',
      'Org Chart',
      'Time Off management',
      'Seperation/ Offboarding'
    ],
    logo: FreshteamLogo
  },
  {
    name: 'Freshdesk Customer Success',
    category: 'Customer Experience',
    description:
      'Protect revenue, increase customer lifetime value, and strengthen customer relationships.',
    colorCode: '#00b647',
    features: [
      'Account 360',
      'Custom adoption tracking',
      'Customer health score',
      'Proactive Alerts',
      'CSM workflows',
      'Customer segmentation',
      'Task management',
      'Email automation',
      'Team management'
    ],
    logo: FreshsuccessLogo
  }
];

export const accountMigrationDetails = [{
  title:'Plan Changes Unavailable',
  description:'You cannot change your plan type (e.g., monthly to yearly or vice versa) between start_date and end_date.'
},{
  title:'Limited Features & Support',
  description:'If your subscription renews or ends after end_date, you will retain Freshping features until your subscription expires. However, no further product support or new features will be provided during this period.'
}];


export const companySizeOptions = [
  '0 - 10',
  '11 - 50',
  '51 - 200',
  '200 - 500',
  '500 - 1000',
  '1000+'
];

export const productCategories = [
  'All',
  'Customer Experience',
  'Sales and Marketing',
  'IT Service Management',
  'HR Management'
];

export const dateFormat = 'MMMM D, YYYY';

export const bodyContent = [
  {
    title: 'What happens after I begin the export?',
    description:
      "Your export process starts in the background. Within 48 hours, you'll receive an email with a link to download a ZIP file containing your exported data. This same e-mail will also be received by all account admins and owners. This link will be active for 60 days from the email date."
  },
  {
    title: 'Can I take multiple exports?',
    description:
      'Yes, you can multiple exports till your account is shut down. If your export is already in progress you will have to wait till it is completed in order to submit another export. All the links in your previous email will give you the latest export file once it has been processed'
  },
  {
    title: 'Who can I contact for support during this transition?',
    description:
      'Our dedicated transition team at support.freshping@freshworks.com is here to help you through every step of the way, including data export, migration assistance, and answering any specific questions you may have.'
  },
  {
    title:
      'What happens if my paid account is up for renewal before the shutdown?',
    description:
      "You can still renew your subscription until end_date. All Freshping features will remain available until your subscription expires, but we won't be able to provide support or deliver any new features during this time."
  },
  {
    title: 'What happens after my account is disabled?',
    description:
      'Your data will be stored in our database for <b>60 days</b> and will be available for export. After this period, your data will be permanently deleted in compliance with GDPR standards.'
  }
];

export const FREE_EXTEND_DEFAULT = 30;
export const PAID_EXTEND_DEFAULT = 60;