import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

import CautionOutline from '../images/caution_outline.svg';
import { getOrgId, getTimezoneDate } from '../utils/utility';
import { request } from '../utils/Api';
import MigrationBannerDrawer from './MigrationBannerDrawer';
import MigrationBannerExportModal from './MigrationBannerExportModal';
import {
  dateFormat,
  FREE_EXTEND_DEFAULT,
  PAID_EXTEND_DEFAULT
} from '../constants/constant';

const MigrationBanner = ({ currentOrg }) => {
  const [showExportButton, setShowExportButton] = useState(true);
  const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [bannerText, setBannerText] = useState('');
  const {
    deprecation,
    subscription,
    timezone,
    logged_in_user_role
  } = currentOrg;
  useEffect(() => {
    setBannerText(getRetiringBannerMessage());
  }, []);

  const initiateExport = async () => {
    if (logged_in_user_role === 'ST') {
      return toastr.warning(
        "Export request can't be processed. Only an Admin or Owner can request a data export."
      );
    }
    const { status, response, message } = await request(`/export`, {
      method: 'POST',
      payload: { organization: getOrgId() }
    });
    if (status === 400 && response && response.message) {
      return toastr.warning(response.message);
    }
    if (message) {
      toastr.success(message);
      setShowLearnMoreModal(false);
      setShowExportModal(false);
    }
  };

  const getRetiringBannerMessage = () => {
    const { end_date, free_extend, paid_extend } = deprecation;
    const { current_term_end, cb_plan } = subscription;
    const retireDate = moment(end_date, 'YYYY-MM-DD');
    let retireBannerText = '';

    if (cb_plan.includes('sprout')) {
      const freeUserDaysToGoAfterRetire = free_extend || FREE_EXTEND_DEFAULT;
      const freeUserAccountExpireDate = retireDate
        .clone()
        .add(freeUserDaysToGoAfterRetire, 'days');
      const noOfDaysToGo = retireDate.diff(moment().startOf('day'), 'days');
      if (noOfDaysToGo > 0) {
        setShowExportButton(false);
        retireBannerText = `${noOfDaysToGo} days to go. Freshping is retiring on ${retireDate.format(
          dateFormat
        )} and your account will be closed shortly after. Export your data now to make your transition seamless.`;
      } else {
        setShowExportButton(true);
        retireBannerText = `You have until ${freeUserAccountExpireDate.format(
          dateFormat
        )} to export your data before your account is permanently deleted.`;
      }
    } else if (cb_plan.includes('garden') || cb_plan.includes('blossom')) {
      const accountExpireDate = moment(
        getTimezoneDate(current_term_end, timezone),
        'D MMMM YYYY'
      );
      if (retireDate.isAfter(moment(), 'day')) {
        setShowExportButton(false);
        retireBannerText = `Freshping is retiring on ${retireDate.format(
          dateFormat
        )} and renewals will no longer be available. You can now safely export your data for a seamless transition.`;
      } else if (accountExpireDate.isSameOrAfter(moment(), 'day')) {
        setShowExportButton(false);
        const accountExpireNoOfDaysToGo = accountExpireDate.diff(
          moment().startOf('day'),
          'days'
        );
        retireBannerText = `${accountExpireNoOfDaysToGo} days to go. Your account will be closed on ${accountExpireDate.format(
          dateFormat
        )}. Export your data now to ensure a smooth transition.`;
      } else {
        const paidUserDaysToGoAfterExpire = paid_extend || PAID_EXTEND_DEFAULT;
        const paidUserAccountExpireDate = (accountExpireDate || retireDate)
          .clone()
          .add(paidUserDaysToGoAfterExpire, 'days');

        setShowExportButton(true);
        retireBannerText = `You have until ${paidUserAccountExpireDate.format(
          dateFormat
        )} to export your data before your account is permanently deleted.`;
      }
    }

    return retireBannerText;
  };

  return (
    <>
      {bannerText && (
        <StyledMigrationBanner>
          <div className="caution-icon">
            <img src={CautionOutline} alt="" role="presentation" />
          </div>
          <p className="content">{bannerText}</p>
          <button
            className="btn-as-link"
            type="button"
            onClick={() =>
              showExportButton
                ? setShowExportModal(true)
                : setShowLearnMoreModal(true)
            }
          >
            {showExportButton ? 'Export Now' : 'Learn more'}
          </button>
          {showLearnMoreModal && (
            <MigrationBannerDrawer
              showLearnMoreModal={showLearnMoreModal}
              setShowLearnMoreModal={setShowLearnMoreModal}
              setShowExportModal={setShowExportModal}
              deprecation={deprecation}
            />
          )}
          {showExportModal && (
            <MigrationBannerExportModal
              initiateExport={initiateExport}
              setShowExportModal={setShowExportModal}
            />
          )}
        </StyledMigrationBanner>
      )}
    </>
  );
};

export default MigrationBanner;

const StyledMigrationBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px;
  font-size: 14px;
  font-weight: 700;
  background-color: #ffe9ee;
  color: #27313b;
  box-shadow: inset 0px -1px 0px #b0d5fc;
  margin-bottom: 8px;

  .caution-icon {
    width: 20px;
    height: 20px;
  }

  .content {
    margin: 0;
  }

  .btn-as-link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: #2e66d0;
  }
`;
