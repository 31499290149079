/* eslint-disable arrow-parens */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Button } from 'freemium-ui';
import Tooltip from '../../components/styled/Tooltip';
import {
  PLAN_META,
  INTEREST_LOG,
  PLANS_FEATURES
} from '../../constants/constant';
import tickImg from '../../images/tick.svg';
import {
  Plan,
  PlanLabel,
  SecondaryButton,
  Features,
  Banner,
  PriceTag,
  InterestBanner,
  Downgrade,
  Suspended
} from './style';
import { datewithTimezone, getTimezoneDate } from '../../utils/utility';
import Icons from '../../components/styled/Icons';

function getPlanData(plans, addons, unitPrice, planName) {
  const filtered = plans.find(plan => plan.cb_plan_id === planName);

  const totalchecks = addons.reduce(
    (total, current) =>
      total + Number(PLAN_META.addons[current.cb_id] * current.quantity),
    0
  );

  const totalPrice = addons.reduce(
    (total, current) =>
      total + Number(current.unit_price / 100) * current.quantity,
    unitPrice / 100
  );

  return {
    filtered,
    totalchecks,
    totalPrice
  };
}

export const ActivePlan = props => {
  const { subscription, timezone } = props.org;
  const {
    addons,
    // started_at,
    next_billing_at,
    cb_plan,
    billing_period_unit,
    plan_unit_price,
    status,
    trial_end,
    plan_name,
    current_term_start,
    current_term_end
  } = subscription;

  const { filtered, totalchecks, totalPrice } = getPlanData(
    props.plans,
    addons,
    plan_unit_price,
    cb_plan
  );
  const subscriptionRenewal = getTimezoneDate(next_billing_at, timezone);
  const trailEndDate = getTimezoneDate(trial_end, timezone);
  const trialEnd = datewithTimezone(trial_end, timezone);
  const startedAt = getTimezoneDate(current_term_start, timezone);
  const endedAt = getTimezoneDate(current_term_end, timezone);
  const isSprout = cb_plan.indexOf('sprout') > -1;
  const trailRemaining =
    moment(trialEnd).diff(datewithTimezone(moment(), timezone), 'days') + 1;
  return (
    <Banner
      className={
        trailRemaining && trailRemaining < 22
          ? `${plan_name} active`
          : `${plan_name}`
      }
    >
      <Fragment>
        <div style={{ fontSize: '16px' }}> Your current plan </div>
        <div style={{ display: 'flex' }}>
          <div className="planname">
            {plan_name}
            {filtered && (
              <span className="checks" style={{ paddingLeft: '10px' }}>
                ({filtered.limits.check_count} checks)
              </span>
            )}
          </div>
          {status === 'in_trial' && trial_end && trailRemaining < 22 ? (
            <PriceTag
              className={
                trailRemaining <= 7 ? 'highlight warning' : 'highlight'
              }
            >
              <span>Trial remaining </span>
              <span style={{ fontWeight: 'bold' }}>{trailRemaining} days</span>
            </PriceTag>
          ) : (
            <PriceTag className="highlight">
              {totalPrice ? (
                <Fragment>
                  <span>
                    Billed(
                    {billing_period_unit === 'month' ? 'monthly' : 'annually'})
                  </span>
                  <b>${totalPrice}</b>
                </Fragment>
              ) : (
                <div style={{ fontWeight: '600' }}>FREE FOREVER</div>
              )}
            </PriceTag>
          )}
        </div>
        <div className="checks">
          {!isSprout ? (
            <div style={{ paddingTop: '5px', display: 'flex' }}>
              Next billing date:&nbsp;
              <div>
                <b>{subscriptionRenewal}</b>
              </div>
            </div>
          ) : null}
        </div>
        <div className="additional">
          {totalchecks && !isSprout ? (
            <div>
              Additonal checks:&nbsp;
              <b>{totalchecks}</b>
            </div>
          ) : null}
        </div>
        {!isSprout &&
        status !== 'in_trial' &&
        trial_end &&
        trailRemaining < 22 ? (
          <div>
            <div className="checks">
              Billing cycle:{' '}
              <b>
                {startedAt} - {endedAt}
              </b>
            </div>
          </div>
        ) : null}
      </Fragment>
      {status === 'in_trial' && trailRemaining < 22 && (
        <div className="suspensionInfo">
          {isSprout ? (
            <span>
              *Your trial will end on <b>{trailEndDate}</b>. You can choose to
              try Blossom or Garden plans for free, till then.
            </span>
          ) : (
            <span>
              *Your account will be suspended on
              <b> {trailEndDate}</b>. Pay or choose sprout plan to avoid
              suspension
            </span>
          )}
        </div>
      )}
    </Banner>
  );
};

export const PlanInfo = ({ plan }) => (
  <Fragment>
    {plan.price ? (
      <div className="price">
        <Fragment>
          <span className="dollar">$</span>
          {plan.price}
          <span className="term">/ Month</span>
        </Fragment>
      </div>
    ) : (
      <div className="price"> FREE FOREVER </div>
    )}
    <div className="checksavailable"> {plan.limits.check_count} Checks </div>
    <div className="additional">
      Additional checks
      <b>{plan.name !== 'SPROUT' ? ' can' : ' cannot'}</b> be added
    </div>
  </Fragment>
);

export const PlanCard = props => {
  const { plan, currentPlan, toggle, subscription, isTooltip } = props; //triggerModal,
  const { cb_plan_id, name, description } = plan; //limits
  const { status, plan_name } = subscription;
  const isSprout = plan_name === 'SPROUT' && cb_plan_id.indexOf('sprout') > -1;
  const isCurrentPlan =
    status === 'in_trial'
      ? isSprout || name === plan_name
      : isSprout || cb_plan_id === currentPlan;
  return (
    <Fragment>
      {PLANS_FEATURES[name] ? (
        <Plan className={isCurrentPlan ? 'active' : ''}>
          {isCurrentPlan ? (
            <PlanLabel>
              {status === 'in_trial' && !isSprout
                ? 'Your trial plan'
                : 'Your plan'}
            </PlanLabel>
          ) : ''}
          <div className="default-info">
            <div className="planname"> {name} </div>
            <PlanInfo plan={plan} toggle={toggle} />
          </div>
          <PlanActions {...props} />
          <hr />
          <div className="info"> {description} </div>

          <Features>
            {PLANS_FEATURES[name].default.map((feature, i) => (
              <div className="item" key={i}>
                <div className="tick">
                  <img src={tickImg} alt="tick" />
                </div>
                {feature}
              </div>
            ))}
            <AdditionalFeatures planName={name} />
          </Features>
        </Plan>
      ) : (
        ''
      )}
    </Fragment>
  );
};

function AdditionalFeatures(props) {
  const { additional } = PLANS_FEATURES[props.planName];
  return (
    <Fragment>
      {Object.keys(additional).map((key, i) => (
        <Fragment>
          <div className="item" key={`${key}-${props.planName}-${i}`}>
            <div className="tick">
              <img src={tickImg} alt="tick" />
            </div>
            <b>{additional[key].title}</b>
          </div>
          <Fragment>
            {additional[key].features.map((k, j) => (
              <div className="subitem" key={`${key}-sub-${j}`}>
                {k}
              </div>
            ))}
          </Fragment>
        </Fragment>
      ))}
      {props.planName === 'SPROUT' && (
        <div className="subitem">
          {' '}
          Freshstatus (Basic) <sup>#</sup>
        </div>
      )}
    </Fragment>
  );
}

const PlanActions = props => {
  const {
    plan,
    currentPlan,
    onTry,
    period_unit,
    isTooltip,
    owner,
    triggerModal,
    subscription,
    downgradePlan,
    switchTrial,
    deprecationEndDate
  } = props;
  const { cb_plan_id, name, price } = plan;
  const downgradeTrialCheck =
    subscription.status === 'in_trial' ? 'Downgrade' : 'Request Downgrade';

  const isSprout = cb_plan_id.indexOf('sprout') > -1;
  let buttonTxt;
  if (currentPlan.indexOf('garden') > -1) {
    buttonTxt =
      name === 'BLOSSOM' || name === 'SPROUT'
        ? downgradeTrialCheck
        : subscription.status === 'in_trial'
        ? 'Activate Now'
        : subscription.status !== 'in_trail' && currentPlan === cb_plan_id
        ? 'Add / Remove Checks'
        : 'Choose Plan';
  } else if (currentPlan.indexOf('blossom') > -1) {
    buttonTxt =
      name === 'SPROUT'
        ? downgradeTrialCheck
        : subscription.status === 'in_trial' && name === 'BLOSSOM'
        ? 'Activate Now'
        : subscription.status === 'in_trial'
        ? 'Try this Plan'
        : subscription.status !== 'in_trail' && currentPlan === cb_plan_id
        ? 'Add / Remove Checks'
        : 'Choose Plan';
  } else if (currentPlan.indexOf('sprout') > -1) {
    buttonTxt =
      name === 'SPROUT'
        ? 'Add / Remove Checks'
        : subscription.status === 'in_trial'
        ? 'TRY THIS PLAN'
        : 'Choose Plan';
  }
  if (!owner) {
    return (
      <Fragment>
        {!owner && (
          <Button
            type="secondary"
            inline
            disabled={isSprout || deprecationEndDate}
            onClick={() => triggerModal('noaccess')}
          >
            {cb_plan_id === currentPlan ? 'Add / Remove Checks' : buttonTxt}
          </Button>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {buttonTxt.indexOf('Downgrade') > -1 ? (
        <Button
          inline
          type="secondary"
          disabled={deprecationEndDate}
          onClick={
            buttonTxt.indexOf('Request') > -1
              ? () =>
                  triggerModal(
                    'downgrade',
                    plan.name + ' - ' + plan.period_unit
                  )
              : () => downgradePlan(cb_plan_id)
          }
        >
          {buttonTxt}
        </Button>

      ) : buttonTxt.indexOf('Try') > -1 ? (
        <Button
          type="secondary"
          inline
          disabled={isSprout || deprecationEndDate}
          onClick={() => switchTrial(cb_plan_id)}
        >
          {buttonTxt}
        </Button>
      ) : (
        <Tooltip
          position="top"
          style={{display: buttonTxt === 'TRY THIS PLAN' ? 'block' : 'none'}}
          content={buttonTxt === 'TRY THIS PLAN' ? 'Plan upgrade suspended temporarily' : ''}
        >
          <Link
            to={{ pathname: 'estimate' }}
            className={name === 'SPROUT' ? 'disabled' : ''}
          >
            <Button
              inline
              type={buttonTxt === 'Activate Now' ? 'primary' : 'secondary'}
              disabled={true}
              onClick={() => {}}
            >
            {buttonTxt}
            </Button>
          </Link>
        </Tooltip>
      )}
    </Fragment>
  );
};

export const Interest = ({ state }) => {
  const { msg, planname } = state && INTEREST_LOG[state];
  return (
    <InterestBanner>
      <span>{msg} </span>
      <b>&nbsp;{planname}</b>
    </InterestBanner>
  );
};

export const DowngradeModal = props => {
  const { org, plans, newplan } = props;
  const currentPlan = plans.find(
    plan => plan.cb_plan_id === org.subscription.cb_plan
  );
  const newPlanDetails = plans.find(plan => plan.cb_plan_id === newplan);
  // const blossom = plans.find(plan => plan.cb_plan_id === "blossom");
  const { number_of_integrations, number_of_checks } = org;
  const {
    integrations_count,
    check_count,
    data_storage_years
  } = newPlanDetails.limits;
  const isexceeded =
    number_of_integrations > integrations_count ||
    number_of_checks > check_count;

  const remainingChecks = number_of_checks - number_of_checks;
  const remaingIntegrations = number_of_integrations - integrations_count;
  return (
    <ModalTransition>
      <Modal
        heading={undefined}
        autoFocus={false}
        shouldCloseOnOverlayClick
        width="650px"
      >
        <Downgrade>
          <div className="heading">
            <h3> Do you really want to Downgrade? </h3>
            <p>
              Access to all data and configurations associated with features
              available in {currentPlan.name} Plan will be disabled.{' '}
            </p>
          </div>
          <div className="important">
            <span> Important </span> <span>YOU WILL LOSE</span>
          </div>
          <ul>
            <Fragment>
              {currentPlan.feature_json.map(feature => (
                <li className="item" key={`feat_${feature.id}`}>
                  {feature.description}
                </li>
              ))}
            </Fragment>
          </ul>
          <p className="info">
            Note : All data older than{' '}
            {data_storage_years < 1
              ? `${data_storage_years * 12} months`
              : `${data_storage_years} years`}{' '}
            will be deleted and is unrecoverable
          </p>
          {isexceeded && (
            <div className="info alert">
              <div>
                <span>
                  <b>Alert:</b>
                </span>{' '}
                You have{' '}
                <b>
                  {number_of_checks} Checks, {number_of_integrations}{' '}
                  integrations.
                </b>
              </div>
              <div>{`${newPlanDetails.name} plan only allows ${check_count} checks ${integrations_count} integrations.`}</div>
              <div>
                <span> PLEASE DELETE </span>{' '}
                {remainingChecks > 0 && `${remainingChecks} checks`}
                {remaingIntegrations > 0 &&
                  `and ${remaingIntegrations} integrations`}{' '}
                to proceed with downgrading.
              </div>
            </div>
          )}
          <div className="actions">
            <Button
              inline
              type="primary"
              disabled={isexceeded}
              onClick={() => props.downgradePlan(newplan)}
            >
              Yes, Proceed
            </Button>
            <SecondaryButton onClick={props.closeDowngrade}>
              No, Go Back
            </SecondaryButton>
          </div>
        </Downgrade>
      </Modal>
    </ModalTransition>
  );
};

export const AccountSuspension = ({ subscription, timezone }) => {
  const { current_term_end, status, planName } = subscription;
  const currentTermEnd =
    current_term_end &&
    datewithTimezone(moment(current_term_end).add(12, 'hours'), timezone);
  const accountRemaining =
    current_term_end &&
    currentTermEnd.diff(datewithTimezone(moment(), timezone), 'days', true) +
      14;
  const isActive =
    planName !== 'SPROUT' && status === 'active' && accountRemaining > 14;
  return (
    <Fragment>
      {!isActive && accountRemaining ? (
        <Suspended>
          <div className="error">
            <Icons glyph="caution" size="16px" viewBox="0 0 18 18" />
            <span>
              Your account will be suspended in {parseInt(accountRemaining)}
              days
            </span>
          </div>
          <div className="message">
            We are unable to charge your credit card. Please update your card
            details to avoid ACCOUNT SUSPENSION.
          </div>
          <SecondaryButton
            type="button"
            onClick={() => this.updatePaymentMethod()}
          >
            Update card details
          </SecondaryButton>
        </Suspended>
      ) : null}
    </Fragment>
  );
};
