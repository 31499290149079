import React from 'react';
import { request } from '../utils/Api';

class Export extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      export_id: "latest",
      message:
        'Downloading... <br></br> Your download will start automatically.'
    };
  }

  componentDidMount() {
    const token = localStorage.getItem('auth-token');
    if (token && this.state.export_id) {
      const initiateExportDownload = async () => {
        try {
          const migrateInitiate =
            (await request(
              `/export/download?export_id=${this.state.export_id}`,
              { method: 'GET' }
            )) || {};
          if (migrateInitiate.url) {
            window.location.href = migrateInitiate.url;
          }
          if (
            migrateInitiate.status === 400 ||
            migrateInitiate.status === 404
          ) {
            this.setState({
              ...this.state,
              message:
                'There was an error. <br></br> Please try again after some time.'
            });
          }
        } catch (error) {
          this.setState({
            ...this.state,
            message:
              'There was an error. <br></br> Please try again after some time.'
          });
        }
        setTimeout(() => {
          window.close();
        }, 5000);
      };
      initiateExportDownload();
    } else {
      window.location.href = `${window.location.origin}/login/${this.state.export_id}`;
    }
  }
  render() {
    return (
      <div
        style={{ margin: '20px' }}
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );
  }
}

export default Export;
