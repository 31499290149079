import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
`;

export const FormActions = styled.div`
  padding: 16px;
  display: flex;
  border-top: 0.5px solid rgb(232, 232, 232);
  align-items: center;
  justify-content: end;
  background: #f5f7f9;
`;

export const Header = styled.div`
  margin: 25px 20px;
  .content-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #27313b;
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const BodyContent = styled.div`
  margin: 25px 20px;
  .content-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #27313b;
    margin-bottom: 5px;
  }

  .description {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ModalContent = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ModalFooter = styled.footer`
  border-radius: 3px;
  background-color: #f9f9f9;
  min-height: 55px;
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 0 10px;
`;

export const ModalBody = styled.div`
  padding: 0px 20px;
  .body-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const ModalHeader = styled.header`
  padding: 8px 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;
